const header = `
    <link href="https://fonts.googleapis.com/css2?family=Lato:ital@0;1&family=Literata:ital,opsz,wght@0,7..72,600;1,7..72,600&display=swap" rel="stylesheet">
    <link async rel="stylesheet" href="https://cdn.jsdelivr.net/npm/semantic-ui@2/dist/semantic.min.css" />

    <!-- Global site tag (gtag.js) - Google Analytics -->
    <script async src="https://www.googletagmanager.com/gtag/js?id=UA-115172742-1"></script>
    <script>
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'UA-115172742-1');
    </script>
    <!-- Active Campaign Script -->
    <script type="text/javascript">
    (function(e, t, o, n, p, r, i) {
            e.visitorGlobalObjectAlias = n;
            e[e.visitorGlobalObjectAlias] = e[e.visitorGlobalObjectAlias] || function() {
                (e[e.visitorGlobalObjectAlias].q = e[e.visitorGlobalObjectAlias].q || []).push(arguments)
            };
            e[e.visitorGlobalObjectAlias].l = (new Date).getTime();
            r = t.createElement("script");
            r.src = o;
            r.async = true;
            i = t.getElementsByTagName("script")[0];
            i.parentNode.insertBefore(r, i)
        })(window, document, "https://diffuser-cdn.app-us1.com/diffuser/diffuser.js", "vgo");
        vgo('setAccount', '226130963');
        vgo('setTrackByDefault', true);
        vgo('process');
    </script>
    <!-- Escalated Code -->
    <script>
    window.myCallback = function(result, scriptTag, trafficType, risk) {
        /*
        if (trafficType === 1002) {
        window.location.href = "https://app.healthcasts.com/ivt.html";
        return;
        }
        */
    }
    </script>
    `;

const assetStyles = `
    <style>
        .banner {
            background-repeat:no-repeat;
            background-size: cover;
            height: auto;
            padding: 30px 0 30px 30px;
        }
        .tag {
            background-repeat:no-repeat;
            background-size: cover;
            height: auto;
            padding: 30px 10px 30x 0;
            align: right;
        }
        .tag_eventrecap {
            background-image: url("https://app.healthcasts.com/site/supporting_asset_files/php/desktop/assets/MS_TEMPLATES/MS_images/tag-event-recap.png");
        }
        .tag_clinicalperspectives {
            background-image: url("https://app.healthcasts.com/site/supporting_asset_files/php/desktop/assets/MS_TEMPLATES/MS_images/tag-clinical-perspectices.png");
        }
        .tag_communityconsensus {
            background-image: url("https://app.healthcasts.com/site/supporting_asset_files/php/desktop/assets/MS_TEMPLATES/MS_images/tag-community-consensus.png");
        }
        .tag_experoncall {
            background-image: url("https://app.healthcasts.com/site/supporting_asset_files/php/desktop/assets/MS_TEMPLATES/MS_images/tag-expert-oncall.png");
        }
        .tag_expertperspectives {
            background-image: url("https://app.healthcasts.com/site/supporting_asset_files/php/desktop/assets/MS_TEMPLATES/MS_images/tag-expert-perspectives.png");
        }
        .tag_regionalperspectives {
            background-image: url("https://app.healthcasts.com/site/supporting_asset_files/php/desktop/assets/MS_TEMPLATES/MS_images/tag-regional-perspectives.png");
        }
        .banner_inner_area {
            width: 90%;
        }
        .banner_inner_line {
            border-top: #707070  solid 1px;
            width: 90%;
            height: 10px;
            margin: 0px 0px 0px 20px;
        }
        .banner_inner_text1 {
            font-size: 50px;
            line-height: 45px;
            padding: 0px 0px 0px 20px;
            font-family: 'Lato', sans-serif;
            font-weight: 400;
            letter-spacing: 22px;
            color: #ffffff;
            text-align: left;
            text-transform: uppercase;
        }
        .banner_inner_text2 {
            font-size: 27px;
            line-height: 36px;
            padding: 0px 0px 0px 20px;
            font-family: 'Lato', sans-serif;
            font-weight: 400;
            letter-spacing: 20px;
            color: #ffffff;
            text-align: left;
            text-transform: uppercase;
        }
        .banner_inner_text3 {
            font-size: 24px;
            padding: 5px 0px 0px 20px;
            font-family: 'Lato', sans-serif;
            font-weight: 300;
            letter-spacing: 5px;
            color: #ffffff;
            text-align: left;
            text-transform: uppercase;
        }
        .banner_inner_text4 {
            font-size: 20px;
            padding: 0px 0px 0px 20px;
            font-family: 'Lato', sans-serif;
            letter-spacing: 5px;
            font-weight: 300;
            color: #ffffff;
            text-align: left;
            text-transform: uppercase;
        }
        .banner_inner_text5 {
            font-size: 16px;
            padding: 0px 0px 0px 20px;
            font-family: 'Lato', sans-serif;
            font-weight: 300;
            color: #ffffff;
            text-align: left;
            text-transform: uppercase;
        }
        .banner_portrait {
            height: auto;
            width: 90%;
            max-width: 100px;
        }
        .banner_portrait_col {
            width: 20%;
            max-width: 150px;
        }
        .banner_tall {
            padding: 30px 0 30px 0;
        }
        .banner_logo {
            height: 10%;
            width: auto;
            max-height: 75px;
            align: left;
            padding: 30px 0 30px 30px;
        }
        .banner_ad {
            width: auto;
            max-height: 90px;
            align: center;
            padding: 0 0 0 0;
        }
        .main_body{
            width: 80%;
            max-width: 1200px;
            min-height: 1000px; height: auto;
            overflow: auto;
            background: #ffffff;
            -webkit-box-shadow: 0px 10px 24px 0px rgba(0,0,0,0.18);
            -moz-box-shadow: 0px 10px 24px 0px rgba(0,0,0,0.18);
            box-shadow: 0px 10px 24px 0px rgba(0,0,0,0.18);
        }
        .main_body_text8 {
            background: #F8F7F7;
            background-repeat:no-repeat;
            background-size: 60px 60px;
            background-position: 10px 30px;
            font-size: 15px;
            padding: 20px 30px 30px 30px;
            font-family: 'Lato', sans-serif;
            font-weight: 500;
            color: #282440;
            text-align: left;
        }
        .main_body_text1 {
            font-size: 18px;
            padding: 10px 30px 10px 30px;
            font-family: 'Lato', sans-serif;
            font-weight: 300;
            color: #39312b;
            text-align: left;
        }
        .adplugg-tag {
            width: 100%;
        }
        .main_body_textAD {
            font-size: 9px;
            padding: 5px 0 30px 0;
            font-family: 'Lato', sans-serif;
            font-weight: 500;
            color: #282440;
            text-align: center;
        }
        .color_option0{
            color: #707070;
        }
        @media (min-width: 601px) and (max-width: 900px) {
            .banner {
                background-size: cover;
                height: auto;
            }
            .banner_inner_area {
                width: 95%;
            }
            .banner_inner_text1 {
                font-size: 40px;
                line-height: 36px;
                letter-spacing: 22px;
            }
            .banner_inner_text2 {
                font-size: 15px;
                line-height: 23px;
                letter-spacing: 18px;
            }
            .banner_inner_text3 {
                font-size: 15px;
            }
            .footer_sizing {
                height: 150px;
            }
            .main_body{
                width: 95%;
            }
        }

        @media (min-width: 300px) and (max-width: 600px) {
            .banner {
                background-size: cover;
                height: auto;
                padding: 10px 0 10px 10px;
            }
            .banner_inner_area {
                width: 100%;
            }
            .banner_inner_text1 {
                font-size: 24px;
                line-height: 27px;
                letter-spacing: 9px;
            }
            .banner_inner_text2 {
                font-size: 16px;
                line-height: 22px;
                letter-spacing: 9px;
            }
            .banner_inner_text3 {
                font-size: 13px;
            }
            .banner_inner_text4 {
                font-size: 15px;
            }
            .banner_inner_text4 {
                font-size: 13px;
            }
            .banner_portrait {
                height: auto;
                width: 100%;
                min-width: 70px;
            }
            .banner_portrait_col {
                width: 25%;
                max-width: 100px;
            }
            .footer_sizing {
                height: 100px;
            }
            .main_body{
                width: 95%;
            }
            .main_body_text1 {
                font-size: 16px;
                word-wrap: break-word;
            }
            .main_body_text2 {
                font-size: 17px;
            }
            .main_body_text3 {
                font-size: 14px;
                background-size: 0px 0px;
                padding: 10px 10px 10px 10px;
            }
            .main_body_text3-kol {
                font-size: 14px
                background-size: 60px 60px;
                padding: 10px 10px 10px 80px;
            }
            .main_body_text3-kol2 {
                font-size: 14px
                background-size: 60px 60px;
                padding: 10px 10px 10px 80px;
            }
            .main_body_text3-hc {
                font-size: 14px
                background-size: 60px 60px;
                padding: 10px 10px 10px 80px;
            }
            .main_body_text3-quote {
                font-size: 14px;
            }
            .main_body_text4 {
                font-size: 14px;
            }
            .main_body_text9 {
                font-size: 16px;
                padding: 5px 5px 5px 5px;
            }
            .main_body_text10 {
                font-size: 10px;
                padding: 0px;
            }
        }
        // consensus styles

        .main-container {
            background-color: #fff;
        }

        .consensus-container {
            font-family: "Lato", sans-serif;
            box-shadow: 0px 6px 10px rgba(39, 42, 44, 0.14), 0px 1px 18px rgba(39, 42, 44, 0.12), 0px 3px 5px -1px rgba(39, 42, 44, 0.2);
            color: #032246;
            background: #FFFFFF;
            position: relative;
          }
          .consensus-container .consensus-header {
            background: #F7F6F8;
            padding: 48px 220px;
          }
          @media only screen and (max-width: 1350px) {
            .consensus-container .consensus-header {
              padding: 80px;
            }
          }
          @media only screen and (max-width: 768px) {
            .consensus-container .consensus-header {
              padding: 16px;
            }
          }
          .consensus-container h1 {
            font-family: "Literata", serif;
            font-size: 36px;
            line-height: 130%;
            letter-spacing: -0.015em;
            margin-bottom: 20px;
            font-weight: 600;
            color: #0C0033;
            position: relative;
            z-index: 2;
            word-break: break-word;
          }
          @media only screen and (max-width: 768px) {
            .consensus-container h1 {
              font-size: 28px;
              margin-bottom: 24px;
            }
          }
          .consensus-container h2 {
            font-family: "Literata", serif;
            font-weight: 600;
            font-size: 28px;
            line-height: 130%;
            letter-spacing: -0.015em;
            margin-bottom: 32px;
          }
          @media only screen and (max-width: 768px) {
            .consensus-container h2 {
              font-size: 24px;
              margin-bottom: 24px;
            }
          }
          .consensus-container p, .consensus-container ol, .consensus-container ul {
            font-weight: 400;
            font-size: 18px;
            line-height: 150%;
            margin-bottom: 40px;
            word-break: break-word;
          }
          @media only screen and (max-width: 768px) {
            .consensus-container p {
              font-size: 16px;
              margin-bottom: 24px;
              line-height: 142%;
            }
          }

          .consensus-container ol, .consensus-container ul {
            list-style-position: outside;
            padding-left: 20px;
            margin-top: 0;
          }

          .consensus-container .author {
            padding: 0px;
            margin-bottom: 30px;
            position: relative;
            z-index: 2;
            display: flex;
            flex-flow: row wrap;
          }
          .consensus-container .author-avatar {
            width: 80px;
            height: 80px;
            margin: 0px 20px 0px 0px;
            border-radius: 50%;
            -moz-border-radius: 50%;
            -webkit-border-radius: 50%;
          }
          .consensus-container .author-info {
            padding: 10px 0px 0px 0px;
          }
          .consensus-container .author-name {
            color: #09399A;
            font-size: 20px;
          }
          .consensus-container .author-titles {
            margin: 8px 0px 0px 0px;
          }
          .consensus-container .quote {
            border-left: 4px solid #3C6CCD;
            background: rgba(231, 237, 249, 0.5);
            padding: 32px;
            margin-bottom: 24px;
            display: flex;
            flex-flow: column wrap;
          }
          @media only screen and (max-width: 768px) {
            .consensus-container .quote {
              margin-bottom: 16px;
              padding: 16px;
            }
          }
          .consensus-container .quote-avatar {
            width: 60px;
            height: 60px;
            margin: 0px 10px 0px 0px;
            border-radius: 50%;
            -moz-border-radius: 50%;
            -webkit-border-radius: 50%;
          }
          .consensus-container .quote-info-avatar {
            display: flex;
            flex-flow: row wrap;
          }
          .consensus-container .quote-text {
            font-weight: 400;
            font-size: 16px;
            line-height: 130%;
            margin-bottom: 16px;
            letter-spacing: -0.01em;
          }
          .consensus-container .quote-text p {
            margin-bottom: 16px;
          }
          .consensus-container .quote-text p::before {
            content: open-quote;
          }
          .consensus-container .quote-text p::after {
            content: close-quote;
          }
          @media only screen and (max-width: 768px) {
            .consensus-container .quote-text {
              font-size: 16px;
              line-height: 142%;
            }
          }
          .consensus-container .quote-author {
            font-weight: 400;
            font-size: 16px;
            line-height: 125%;
            color: #3C6CCD;
            margin: 20px 0px 0px 0px;
          }
          @media only screen and (max-width: 768px) {
            .consensus-container .quote-author {
              font-size: 14px;
              line-height: 142%;
            }
          }
          .consensus-container .case {
            padding: 36px 32px 42px 32px;
            background: #FFFFFF;
            border: 1px solid #EEEDF0;
            box-shadow: 4px 4px 8px rgba(12, 0, 51, 0.08), 8px 8px 24px rgba(12, 0, 51, 0.08);
            border-radius: 16px;
            position: relative;
            z-index: 2;
          }
          @media only screen and (max-width: 768px) {
            .consensus-container .case {
              margin-bottom: 40px;
              padding: 16px;
            }
          }
          .consensus-container .case-title {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 28px;
          }
          @media only screen and (max-width: 768px) {
            .consensus-container .case-title {
              flex-direction: column;
              margin-bottom: 16px;
            }
          }
          .consensus-container .case-title .chip {
            margin-bottom: 0px;
          }
          @media only screen and (max-width: 768px) {
            .consensus-container .case-title .chip {
              margin-bottom: 12px;
            }
          }
          .consensus-container .case-title .case-data {
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            color: #6D6685;
            margin-bottom: 0px;
          }
          .consensus-container .case-text {
            margin-bottom: 0px;
            line-height: 130%;
            letter-spacing: -0.01em;
          }
          .consensus-container .case-text p.has-strong {
            font-family: 'Literata', serif;
            margin-bottom: 16px;
            color: #0B47C1;
            font-weight: 600;
          }
          .survey-container {
            font-family: "Lato", sans-serif;
            color: #032246;
            background: #FFFFFF;
            position: relative;
          }
          .consensus-container .consensus-body, .survey-container .survey-body {
            margin-left: 0;
            margin-right: 0;
            padding: 48px 220px;
        }
        @media only screen and (max-width: 1350px) {
            .consensus-container .consensus-body {
                padding: 80px 80px 10px 80px;
            }

            .survey-container .survey-body {
                padding: 0 80px 80px 80px;
            }
        }
        @media only screen and (max-width: 768px) {
            .consensus-container .consensus-body {
              padding: 16px 16px 10px 16px;
              margin-left: 0px;
              margin-right: 0px;
            }

            .survey-container .survey-body {
                padding: 0 16px 16px 16px;
            }
          }
          .consensus-container .consensus-body .title, .consensus-container .consensus-body .title > p {
            color: #0052CC;
            font-weight: 400;
            font-size: 28px;
            line-height: 130%;
            letter-spacing: -0.01em;
            word-break: break-word;
            margin-bottom: 40px;
          }
          @media only screen and (max-width: 768px) {
            .consensus-container .consensus-body .title {
              font-size: 22px;
              margin-bottom: 24px;
              line-height: 110%;
            }
          }
          .consensus-container .consensus-body .chip {
            margin-bottom: 16px;
          }
          .consensus-container .consensus-body .quotes-container {
            margin-bottom: 64px;
          }
          @media only screen and (max-width: 768px) {
            .consensus-container .consensus-body .quotes-container {
              margin-bottom: 32px;
            }
          }
          .consensus-container .consensus-body .definitions,
          .consensus-container .consensus-body .references {
            color: #6D6685;
            margin-bottom: 40px;
          }
          .consensus-container .consensus-body .definitions > h2,
          .consensus-container .consensus-body .references > h2 {
            font-size: 16px;
            line-height: 125%;
            margin-bottom: 16px;
            font-weight: 600;
          }
          @media only screen and (max-width: 768px) {
            .consensus-container .consensus-body .definitions > h2,
          .consensus-container .consensus-body .references > h2 {
              font-weight: 400;
              font-size: 14px;
              line-height: 142%;
              margin-bottom: 8px;
            }
          }
          .consensus-container .consensus-body .definitions li > p,
          .consensus-container .consensus-body .references li > p {
            font-size: 14px;
            margin-bottom: 0px;
          }
          .consensus-container .consensus-body .definitions ul {
            list-style: none;
            padding-left: 0px;
          }
          .consensus-container .consensus-body .references ol {
            list-style: decimal;
            padding-left: 15px;
          }
          .consensus-container .consensus-body .source-information {
            color: #6D6685;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
          }
          .consensus-container #sources-container {
            display: flex;
            column-gap: 5px;
            color: #6D6685;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            margin-bottom: 30px;
          }
          .consensus-container #sources-container .source-information {
                color: #6D6685;
                font-weight: 400;
                font-size: 14px;
                line-height: 140%;
                margin-bottom: 10px;
          }
        .chip {
            color: #fff !important;
            font-size: 12px !important;
            padding: 4px 8px;
            background: #8651CC;
            border-radius: 100px;
            width: fit-content;
        }
        .chip.purple {
            background: #8651CC;
        }
        .chip.blue {
            background: #0B47C1;
        }
        .action-link-tiles{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 24px;
            padding: 0 220px 96px 220px;
            font-family: 'Lato', sans-serif;
            background: #FFFFFF;
            position: relative;
        }
        @media only screen and (max-width: 1350px) {
            .action-link-tiles {
                padding: 0 80px 96px 80px;
            }
        }
        @media only screen and (max-width: 768px) {
            .action-link-tiles {
                flex-direction: column;
                padding: 0 24px 96px 24px;
            }
        }
        .action-link-tile {
            display: flex;
            flex-direction: column;
            padding: 24px 24px 32px;
            gap: 24px;
            background: #FFFFFF;
            border: 1px solid #E9EEFA;
            box-shadow: 4px 4px 8px rgba(12, 0, 51, 0.08), 8px 8px 24px rgba(12, 0, 51, 0.08);
            border-radius: 8px;
        }
        @media only screen and (min-width: 768px) {
            .action-link-tile {
                flex: 1 1 0;
                width: 0;
            }
        }
        .action-link-tile .cta-text {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        .action-link-tile .cta-text h2 {
            font-family: 'Literata', serif;
            font-size: 22px;
            letter-spacing: -0.005em;
            margin-bottom: 8px;
            color: #041C4D;
        }
        @media only screen and (max-width: 768px) {
            .action-link-tile .cta-text h2 {
                font-family: 'Literata', serif;
                font-size: 18px;
            }
        }
        .action-link-tile .cta-text p {
            font-family: 'Lato';
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            color: #0C0033;
            margin-bottom: 16px;
            flex-grow: 1;
        }
        @media only screen and (max-width: 768px) {
            .action-link-tile .cta-text p {
                font-family: 'Lato';
                font-size: 12px;
                line-height: 150%;
            }
        }
        .action-link-tile .cta-text a {
            font-weight: 700;
            font-size: 16px;
            line-height: 125%;
            color: #8651CC;
        }

        .consensus-container #section-topic-container {
            position: relative;
            display: flex;
            column-gap: 1rem;
            flex-wrap: wrap;
        }

        .consensus-container .section {
            scroll-margin-top: 40px;
            margin-bottom: 40px;
        }

        #top-banner-container {
            position: relative;
            display: block;
            padding: 20px 0;
            background-color: #f7f6f8;
        }

        #top-banner {
            width: auto;
            height: auto;
            background: transparent;
            position: relative;
            display: flex;
            margin: 0 auto;
            text-align: center;
            flex-flow: column;
            align-items: center;
        }

        .consensus-container .back-to-top-btn {
            font-size: 15px;
        }

        .consensus-container .back-to-top-btn-box {
            text-align: right;
        }

        .consensus-container .section p:last-child {
            margin-bottom: 20px;
        }

        .consensus-container #topic-selection-container {
            margin: 0 0 50px 0;
        }

        .consensus-container #topic-selection-container #topic-select-heading {
            margin-bottom: 10px;
        }

        .consensus-container #topic-selection-container #topic-anchors {
            display: flex;
            column-gap: 10px;
            row-gap: 10px;
        }

        .consensus-container #topic-selection-container #topic-anchors a {
            padding: 10px 20px;
            color: #FFFFFF;
            background: #0B47C1;
            font-size: 14px;
            border-radius: 100px;
            text-align: center;
            width: 20%;
            display: flex;
            align-items: center;
        }

        .consensus-container #topic-selection-container #topic-anchors a span {
            width: 100%;
            text-align: center;
        }

        .consensus-container #consensus-main-content {
            scroll-margin-top: 100px;
        }

        @media only screen and (max-width: 768px) {
            .consensus-container #topic-selection-container #topic-anchors a {
                width: 100%;
            }
        }
    </style>`;

const adBanner = `
<!-- BANNER AD -->
    <div id="top-banner-container" style="padding: 20px 0 0 0;">
        <div id="top-banner">
            <!-- Top_Page_728x90 [async] -->
            <script type="text/javascript">if (!window.AdButler){(function(){var s = document.createElement("script"); s.async = true; s.type = "text/javascript";s.src = 'https://servedbyadbutler.com/app.js';var n = document.getElementsByTagName("script")[0]; n.parentNode.insertBefore(s, n);}());}</script>
            <script type="text/javascript">
                var AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];
                var abkw = window.abkw || '';
                var plc610311 = window.plc610311 || 0;
                document.write('<'+'div id="placement_610311_'+plc610311+'"></'+'div>');
                AdButler.ads.push({handler: function(opt){ AdButler.register(185098, 610311, [728,90], 'placement_610311_'+opt.place, opt); }, opt: { eventLogData: {"npi" : "<?php echo $user->npiNumber; ?>"}, place: plc610311++, dataKeys:{'db_id':'<?php echo $program->db_id; ?>'}, keywords: abkw, domain: 'servedbyadbutler.com', click:'CLICK_MACRO_PLACEHOLDER', extraData: 'firstname=<?php echo $user->firstName; ?>&lastname=<?php echo $user->lastName; ?>&email=<?php echo $user->email; ?>&npi=<?php echo $user->npiNumber; ?>' }});
            </script>

            <!-- Top_Page_300x250 [async] -->
            <script type="text/javascript">if (!window.AdButler){(function(){var s = document.createElement("script"); s.async = true; s.type = "text/javascript";s.src = 'https://servedbyadbutler.com/app.js';var n = document.getElementsByTagName("script")[0]; n.parentNode.insertBefore(s, n);}());}</script>
            <script type="text/javascript">
                var AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];
                var abkw = window.abkw || '';
                var plc610312 = window.plc610312 || 0;
                document.write('<'+'div id="placement_610312_'+plc610312+'"></'+'div>');
                AdButler.ads.push({handler: function(opt){ AdButler.register(185098, 610312, [300,250], 'placement_610312_'+opt.place, opt); }, opt: { eventLogData: {"npi" : "<?php echo $user->npiNumber; ?>"}, place: plc610312++, dataKeys:{'db_id':'<?php echo $program->db_id; ?>'}, keywords: abkw, domain: 'servedbyadbutler.com', click:'CLICK_MACRO_PLACEHOLDER', extraData: 'firstname=<?php echo $user->firstName; ?>&lastname=<?php echo $user->lastName; ?>&email=<?php echo $user->email; ?>&npi=<?php echo $user->npiNumber; ?>' }});
            </script>

            <!-- Top_mobile_320x50 [async] -->
            <script type="text/javascript">if (!window.AdButler){(function(){var s = document.createElement("script"); s.async = true; s.type = "text/javascript";s.src = 'https://servedbyadbutler.com/app.js';var n = document.getElementsByTagName("script")[0]; n.parentNode.insertBefore(s, n);}());}</script>
            <script type="text/javascript">
                var AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];
                var abkw = window.abkw || '';
                var plc613140 = window.plc613140 || 0;
                document.write('<'+'div id="placement_613140_'+plc613140+'"></'+'div>');
                AdButler.ads.push({handler: function(opt){ AdButler.register(185098, 613140, [320,50], 'placement_613140_'+opt.place, opt); }, opt: { eventLogData: {"npi" : "<?php echo $user->npiNumber; ?>"}, place: plc613140++, dataKeys:{'db_id':'<?php echo $program->db_id; ?>'}, keywords: abkw, domain: 'servedbyadbutler.com', click:'CLICK_MACRO_PLACEHOLDER', extraData: 'firstname=<?php echo $user->firstName; ?>&lastname=<?php echo $user->lastName; ?>&email=<?php echo $user->email; ?>&npi=<?php echo $user->npiNumber; ?>' }});
            </script>
            <div class="ad-indicator">
                Advertisement
            </div>
        </div>
    </div>
<!-- END BANNER AD -->`;

export const typeFormContent = `
    <div class="survey-container">
        <div class="survey-body">
            <iframe src='https://form.typeform.com/to/cns1Pfrs#user_id=<?php echo "{$user->pk_uID}&npi_id={$user->npiNumber}&first_name={$user->firstName}&last_name={$user->lastName}&email={$user->email}&prg_id={$program->db_id}" ?>' width="100%" height="350" frameborder="0" scrolling="no"></iframe>
        </div>
    </div>
    `;

export const footerContent = (demoUserFooterContent, hcpUserFooterContent) => (`
<?php if($user->db_id == 1534778 || $user->db_id == 1534771 || $user->db_id == 1532288){ ?>
    ${demoUserFooterContent}
<?php } else { ?>
    ${hcpUserFooterContent}
<?php } ?>
`);

const tileStyles = `
    <style>
        .tile-content {
            font-family: "Lato", sans-serif;
            color: #0C0033;
        }
        .tile-content .tile-link {
            display: flex;
            width: 260px;
            margin: auto;
        }
        .tile-content .tile-link:hover {
            color: #0C0033;
        }
        .tile-content .tile-link .card {
            display: flex;
            flex-direction: column;
            background: white;
            padding: 16px;
            border-radius: 8px;
            color: black;
            height: 308px;
        }
        .tile-content .tile-link .card .chip {
            margin-bottom: 8px;
        }
        .tile-content .tile-link .card .tile-image,
        .tile-content .tile-link .card .title,
        .tile-content .tile-link .card .sub-title {
            margin-bottom: 8px;
        }
        .tile-content .tile-link .card .title,
        .tile-content .tile-link .card .sub-title {
            flex-grow: 1;
        }
        .tile-content .tile-link .card .tile-image {
            height: 115px;
        }
        .tile-content .tile-link .card .tile-image.community, .tile-content .tile-link .card .tile-image.event-coverage, .tile-content .tile-link .card .tile-image.expert-on-call {
            object-fit: contain;
        }
        .tile-content .tile-link .card .tile-image.clinical {
            border-radius: 50%;
            width: 50%;
            align-self: center;
            object-fit: unset;
        }
        .tile-content .tile-link .card .title {
            font-family: "Literata", serif;
            font-size: 18px;
            margin-top: 0px;
            font-weight: 600;
            line-height: 130%;
        }
        .tile-content .tile-link .card .sub-title {
            font-family: "Lato", serif;
            color: #7A7F94;
            font-size: 12px;
            line-height: 120%;
        }
        .tile-content .tile-link .card .cta {
            display: flex;
            color: #8651CC;
            flex-direction: row;
            width: 100%;
            align-items: center;
            font-size: 14px;
        }
        .tile-content .tile-link .card .cta > svg {
            margin-right: 5px;
        }
        .tile-content .tile-link .card .cta > .cta-text {
            margin-bottom: 0px;
        }

        .chip {
            color: #fff !important;
            font-size: 12px !important;
            padding: 4px 8px;
            background: #8651CC;
            border-radius: 100px;
            width: fit-content;
          }
          .chip.purple {
            background: #8651CC;
          }
          .chip.blue {
            background: #0B47C1;
          }
          .chip.orange {
            background: #B97456;
          }
          .chip.light-blue {
            background: #E7EDF9;
            color: #09399A !important;
          }

    </style>
`;

// TODO: replace the need for this file by allowing a redirect from app.healthcasts.com
export const wrapAssetMarkup = (content, footer) => (
    `<meta name="viewport" content="width=device-width, initial-scale=1">
        ${header}
        ${assetStyles}
        <div class="content">
            <div class="main-container">
                ${adBanner}
                ${content}
            </div>
        </div>
        <div class="content">
            ${typeFormContent}
        </div>
        ${footer}
    </meta>`
);

export const wrapTileMarkup = content => (
    `
    <head>
        <link href="https://fonts.googleapis.com/css2?family=Lato:ital@0;1&family=Literata:ital,opsz,wght@0,7..72,600;1,7..72,600&display=swap" rel="stylesheet">
        ${tileStyles}
    </head>
    <!-- UPDATE ed_path url TO INCLUDE THE APPROPRIATE ASSET_ID -->
    <!-- UPDATE program_id TO REFLECT THE CORRECT VALUE -->
    ${content}
    `
);

export default { wrapAssetMarkup, wrapTileMarkup };
