import React from 'react';
import ActionLinkTile from './actionLinkTile';
import ActionLinkTiles from './actionLinkTiles';

const HcpUserActionLinkTiles = () => (
    <ActionLinkTiles>
        <ActionLinkTile
            title="Gain Clinical Perspective"
            description="Looking for more content on diseases & treatment? Check out the resources page for more clinical insights from your peers."
            cta="View Resources"
            ctaLink="https://app.healthcasts.com/member/index/#"
        />
        <ActionLinkTile
            title="Join a Conversation"
            description="Review patient cases in your and other specialties. Add your opinions and review your colleagues' verbatim comments."
            cta="View Patient Cases"
            ctaLink="https://dx.healthcasts.com/questions"
        />
        <ActionLinkTile
            title="Start a Conversation"
            description="Submit your patient case for review. If selected, your colleagues can discuss on our peer-to-peer network."
            cta="Submit your Patient Case"
            ctaLink="https://ai.consensus-md.com/landing/?dialog=create_case"
        />
    </ActionLinkTiles>
);

export default HcpUserActionLinkTiles;
