export const Permissions = {
    ACTIVE_PATIENT_CASE_MANAGEMENT: 'active_patient_case_management',
    ALL_PATIENT_CASES_MANAGEMENT: 'all_patient_cases_management',
    CONSENSUS_MD_BETA: 'consensus_md_beta',
    CREATE_CASE: 'create_case',
    DEMO_PATIENT_CASES_MY_SPECIALTY: 'demo_patient_cases_my_specialty',
    DEMO_PATIENT_CASES_OTHER_SPECIALTIES: 'demo_patient_cases_other_specialties',
    DISEASES_AND_TREATMENT_INFORMATION: 'diseases_and_treatment_information',
    PATIENT_CASE_SUBMISSION: 'patient_case_submission',
    PATIENT_CASES_MY_SPECIALTY: 'patient_cases_my_specialty',
    PATIENT_CASES_OTHER_SPECIALTIES: 'patient_cases_other_specialties',
    PROGRAMS: 'programs',
    SEARCH: 'search',
    SUMMARY_SUBMISSION: 'summary_submission',
    USER_CONTENT: 'user_content',
    USER_MANAGEMENT: 'user_management',
};
