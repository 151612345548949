import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';
import QuestionItem from './QuestionItem';
import NoQuestions from './NoQuestions';
import { LoadingIndicator } from '../../components';
import AdButlerAd from '../../components/AdButlerAd';
import './style.scss';
import useAdButler from '../../hooks/useAdButler';
import { useViewportWidth } from '../../hooks/ViewportWidthContext';
import RewardsToolTip from './RewardsToolTip';

// This determines if we should use a mobile or desktop ad placement for non-alert, in-feed ads.
const MIN_WIDTH_TO_SHOW_DESKTOP_INFEED_ADS = 1000;

// Page layout metrics used to determine if we show alert ads on the left of the page, or within the feed.
const UI_CONTAINER_WIDTH_LARGE = 960;
const MARGINS_WIDTH = 16;

const QuestionFeed = (props) => {
    const {
        questions: {
            activeQuestions,
            answeredQuestions,
        } = {},
        loading,
        msgVisible,
        hideMsg,
        isMyFeed,
        hasManagementPermission,
    } = props;
    const viewportWidth = useViewportWidth();
    const page = isMyFeed ? 'MY_SPECIALTY' : 'OTHER_SPECIALTIES';
    const alertAdWidth = Number(process.env[`REACT_APP_AD_BUTLER_${page}_ALERT_WIDTH_PX`] || '');
    const alertAdHeight = Number(process.env[`REACT_APP_AD_BUTLER_${page}_ALERT_HEIGHT_PX`] || '');
    // Very wide viewports allow the ad to be shown on the left, otherwise we need to display it within the feed.
    const minWidthToShowAlertOnLeft = UI_CONTAINER_WIDTH_LARGE + ((alertAdWidth + (MARGINS_WIDTH * 2)) * 2);
    const shouldShowAlertAdOnLeft = viewportWidth >= minWidthToShowAlertOnLeft;
    const shouldShowDesktopInFeedAds = viewportWidth >= MIN_WIDTH_TO_SHOW_DESKTOP_INFEED_ADS;
    const alertPlacement = shouldShowAlertAdOnLeft ? 'LEFT' : 'TOP';
    const alertZone = process.env[`REACT_APP_AD_BUTLER_${page}_${alertPlacement}_ALERT`];
    const secondAlertZone = process.env[`REACT_APP_AD_BUTLER_${page}_${alertPlacement}_SECOND_ALERT`];
    const alert = <AdButlerAd zone={alertZone} width={alertAdWidth} height={alertAdHeight} />;
    const secondAlert = <AdButlerAd zone={secondAlertZone} width={alertAdWidth} height={alertAdHeight} />;
    const inFeedAd = useAdButler(page, 'MIDDLE');

    const activeItems = activeQuestions.map(q => (
        <QuestionItem key={q.id} {...q} />
    ));

    const answeredItems = answeredQuestions.map(q => (
        <QuestionItem key={q.id} {...q} isAnswered />
    ));

    const inFeedAdItem = <li className="flex justify-center my-4" key={`ad_butler_ad_${shouldShowDesktopInFeedAds}`}>{inFeedAd}</li>;
    // On desktop, show the in-feed (non-alert) ad after the first question; on mobile, after the second:
    const inFeedAdIndex = shouldShowDesktopInFeedAds ? 1 : 2;
    if (activeItems.length) {
        activeItems.splice(inFeedAdIndex, 0, inFeedAdItem);
    } else if (answeredItems.length) {
        answeredItems.splice(inFeedAdIndex, 0, inFeedAdItem);
    }

    const inFeedAlertAdItem = <li className="flex justify-center gap-2 my-4" key={`ad_butler_ad_${alertZone}`}>{alert}{secondAlert}</li>;
    // On mobile, show the alert after the first question:
    if (!shouldShowAlertAdOnLeft) {
        if (activeItems.length) {
            activeItems.splice(1, 0, inFeedAlertAdItem);
        } else if (answeredItems.length) {
            answeredItems.splice(1, 0, inFeedAlertAdItem);
        }
    }

    const wideScreenAlertAdItem = <div className="absolute left-4">{alert}{secondAlert}</div>;

    let activeItemsArea = null;
    if (!activeQuestions.length && !loading) {
        activeItemsArea = (
            <>
                {inFeedAdItem}
                {inFeedAlertAdItem}
                <NoQuestions hasManagementPermission={hasManagementPermission} isMyFeed={isMyFeed} />
            </>
        );
    } else if (!loading) {
        activeItemsArea = (
            <Fragment>
                <div className="section-header">Featured Questions</div>
                <ul>
                    {activeItems}
                </ul>
            </Fragment>);
    }

    const answeredSection = (answeredQuestions.length && !loading) ? (
        <div className="page-container">
            <div className="section-header">Answered Questions</div>
            <ul>
                {answeredItems}
            </ul>
        </div>
    ) : null;

    return (
        <>
            {shouldShowAlertAdOnLeft ? wideScreenAlertAdItem : null}
            <div className="feed relative" style={shouldShowAlertAdOnLeft ? { minHeight: 600 } : {}}>
                <RewardsToolTip />
                <Message
                    info
                    hidden={!msgVisible}
                    content="You've been redirected here because the page you requested doesn't exist or you don't have permission for it."
                    onDismiss={hideMsg}
                />
                <div className="page-container">
                    <LoadingIndicator active={loading} />
                    {activeItemsArea}
                </div>
                {answeredSection}
            </div >
        </>
    );
};

QuestionFeed.propTypes = {
    questions: PropTypes.shape({
        activeQuestions: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
        })).isRequired,
        answeredQuestions: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
        })).isRequired,
    }).isRequired,
    loading: PropTypes.bool.isRequired,
    hideMsg: PropTypes.func.isRequired,
    msgVisible: PropTypes.bool.isRequired,
    isMyFeed: PropTypes.bool,
    hasManagementPermission: PropTypes.bool.isRequired,
};

QuestionFeed.defaultProps = {
    isMyFeed: false,
};

export default QuestionFeed;
