import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { WithRouterHOC } from '../../components/Router';
import { handle404 } from '../../services/navigation';
import { ID as SessionID } from '../../services/session';
import { STRAPI_ID, getConsensusSummary } from '../../services/strapi/reducer';

const mapStateToProps = (state, router) => {
    const {
        [STRAPI_ID]: {
            resource,
        },
        [SessionID]: {
            id: userId,
        },
    } = state;

    const { match: { params: { id: resourceId, status: resourceStatus } } } = router;

    return ({
        resourceId,
        resourceStatus,
        resource,
        userId,
    });
};

const mapDispatchToProps = dispatch => ({
    notFound: () => dispatch(handle404()),
    fetchConsensusSummary: (id, status, setErrorText) => dispatch(getConsensusSummary({ id, status, setErrorText })),
});


export default Component => compose(WithRouterHOC, connect(mapStateToProps, mapDispatchToProps))(Component);
