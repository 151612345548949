import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from '@reduxjs/toolkit';
import { withRouter } from 'react-router';
import { Formik, Form, Field } from 'formik';
import { string, object, boolean } from 'yup';
import { connect } from 'react-redux';
import { ReloadIcon } from '@radix-ui/react-icons';
import client from '../../api';
import Button from '../components/button';
import RadioGroup from '../components/RadioGroup';
import Error from '../components/Error';
import Input from '../components/Input';
import Select from '../components/Select';
import poastalStates from '../../constants/poastalStates';
import links, { privacyPolicyLink } from '../../links';
import { loadSpecialties } from '../../data/specialties';
import { showErrorToast } from '../../containers/Toast';
import ProductBenefits from '../components/Registration/ProductBenefits';
import { history } from '../../store';

const { REACT_APP_RECAPTCHA_SITE_KEY } = process.env;

const MEMBER_SERVICES = 'MEMBER_SERVICES';
const NPI_ERROR = 'NPI_ERROR';

const STATE_OF_LICENSE = 'STATE_OF_LICENSE';
const FIRST_NAME = 'FIRST_NAME';
const LAST_NAME = 'LAST_NAME';
const NPI = 'NPI';

const NPI_ERROR_MESSAGES = {
    FIRST_NAME: 'The first name you entered does not match the name the NPPES has for this NPI number.',
    LAST_NAME: 'The last name you entered does not match the name the NPPES has for this NPI number.',
    STATE_OF_LICENSE: 'The state you entered does not match the state the NPPES has for this NPI number.',
    NPI: 'This is not a valid NPI number. Please enter your personal NPI number not an organizational NPI.',
};

const INITIAL_FORM_VALUES = {
    firstName: '',
    lastName: '',
    email: '',
    npi: '',
    specialty: '',
    // TODO: this is a placeholder for now. needs to be persisted somewhere.
    profession: 'physician',
    stateOfLicense: '',
    privacyTerms: false,
    emailTerms: false,
};

const PROFESSION_OPTIONS = [
    { value: 'physician', label: 'Physician' },
    { value: 'advanced-practice-nurse', label: 'Advanced Practice Nurse' },
    { value: 'physician-assistant', label: 'Physician Assistant/Associate' },
    { value: 'pharmacist', label: 'Pharmacist' },
    { value: 'student', label: 'Student' },
    { value: 'other-hcp', label: 'Other healthcare professional' },
];

const getRecaptchaToken = () => new Promise((resolve) => {
    window.grecaptcha.ready(async () => {
        const token = await window.grecaptcha.execute(
            REACT_APP_RECAPTCHA_SITE_KEY,
            { action: 'REGISTRATION_FORM' },
        );
        resolve(token);
    });
});

const Registration = ({
    specialties,
    fetchSpecialties,
    dispatchError,
    additionalPayload,
    location,
}) => {
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [formPostSubmitErrors, setFormPostSubmitErrors] = useState([]);

    const queryParams = new URLSearchParams(location.search);
    const initialFormValuesWithPrepopulatedFields = {
        ...INITIAL_FORM_VALUES,
        npi: queryParams.get('npi') || '',
        email: queryParams.get('email') || '',
    };

    useEffect(() => {
        const script = document.createElement('script');

        script.setAttribute('src', `https://www.google.com/recaptcha/api.js?render=${REACT_APP_RECAPTCHA_SITE_KEY}`);
        document.body.appendChild(script);

        if (specialties.length === 0) {
            fetchSpecialties();
        }
    }, []);

    // form schema definition using Yup
    const signupSchema = object().shape({
        firstName: string().required('First name is required.'),
        lastName: string().required('Last name is required.'),
        email: string().email('Please enter a valid email address.').required('Email is required.'),
        npi: string().matches(/^[0-9]{10}$/, 'NPI must be a 10 digit number.').required('NPI is required.'),
        specialty: string().required('Specialty is required.'),
        profession: string().required('Profession is required.'),
        stateOfLicense: string().required('State of license is required.'),
        privacyTerms: boolean().required().oneOf([true], 'You must agree to the privacy policy.'),
        emailTerms: boolean().required().oneOf([true], 'You must agree to receive email communication from Healthcasts.'),
    });

    const submitForm = async (values, actions) => {
        setFormPostSubmitErrors([]);
        actions.setSubmitting(true);

        const token = await getRecaptchaToken();

        const {
            firstName,
            lastName,
            email,
            npi,
            profession,
            specialty,
            stateOfLicense,
            privacyTerms,
            emailTerms,
            caTerms,
        } = values;

        const payload = {
            ...additionalPayload,
            token,
            firstName,
            lastName,
            email,
            npi,
            profession,
            specialtyId: specialty,
            stateOfLicense,
            privacyTerms,
            emailTerms,
            caTerms,
        };

        try {
            await client.post('/register', { data: payload }, false);
            actions.setSubmitting(false);
            setIsSuccess(true);
        } catch (error) {
            actions.setSubmitting(false);
            // we have a collection of ERROR_MESSAGES that we know.
            // if we get one of those, we can display a specific error page.
            if (error?.data?.msg === MEMBER_SERVICES) {
                // If the error condition needs member services
                // intervention, display the RegistrationErrorPage.
                setIsError(true);
            } else if (error?.data?.msg === NPI_ERROR) {
                // fieldsWithErrors is an Array of strings that represent
                // data issuse reported from NPPES. We then use thesse strings
                // to display specific error messages to the user after they
                // submit the form.
                const { fieldsWithErrors } = error?.data?.body;

                setFormPostSubmitErrors(fieldsWithErrors);
            } else {
                // any other error, display a Toast message.
                dispatchError({
                    header: 'We encountered an unexpected issue while processing your request. ',
                    body: 'Contact Member Services for assistance.',
                    link: { href: links.infoPages.support, text: 'Contact Us' },
                });
            }
        }
    };

    const shouldShowErrorMessageSummary = (errors, touched) => {
        const touchedKeys = Object.keys(touched);
        const errorKeys = Object.keys(errors);
        return touchedKeys.some(key => errorKeys.includes(key)) || formPostSubmitErrors.length > 0;
    };

    const getServerErrorForField = (field) => {
        const error = formPostSubmitErrors.find(e => e === field);
        return error ? NPI_ERROR_MESSAGES[field] : '';
    };

    if (isError) {
        history.push(links.registrationPages.memberServices);
    }

    if (isSuccess) {
        history.push(links.registrationPages.success);
    }

    return (
        <div className="mx-0 md:mx-6 xl:mx-auto mb-20 flex flex-col md:flex-row md:rounded-md border bg-gray-100 max-w-screen-xl">
            <div className="md:hidden lg:block lg:w-1/3 bg-purple-200 lg:rounded-tl-md lg:rounded-bl-md"><ProductBenefits /></div>
            <div className="flex flex-col py-10 px-6 sm:px-10 w-full lg:w-2/3">
                <h1 className="mb-6 font-title text-3xl">Create a Free Healthcasts Account</h1>
                <Formik initialValues={initialFormValuesWithPrepopulatedFields} validateOnBlur={false} validateOnChange validationSchema={signupSchema} onSubmit={submitForm}>
                    {({
                        values, errors, touched, isSubmitting,
                    }) => (
                        <Form>
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-10 font-body">
                                <Input id="firstName" name="firstName" label="First Name" serverErrorMessage={getServerErrorForField(FIRST_NAME)} />
                                <Input id="lastName" name="lastName" label="Last Name" serverErrorMessage={getServerErrorForField(LAST_NAME)} />
                                <Input id="email" name="email" label="Email" type="email" />
                                <Input id="npi" name="npi" label="NPI" placeholder="10 digit number" serverErrorMessage={getServerErrorForField(NPI)} />
                                <div className="flex flex-col">
                                    <label className="text-base font-body mb-2" htmlFor="specialty">Specialty</label>
                                    <Select id="specialty" name="specialty" options={specialties} />
                                </div>
                                <div className="flex flex-col">
                                    <label className="text-base font-body mb-2" htmlFor="stateOfLicense">State of Practice</label>
                                    <Select
                                        id="stateOfLicense"
                                        name="stateOfLicense"
                                        options={poastalStates.map(s => ({ id: s, name: s }))}
                                        serverErrorMessage={getServerErrorForField(STATE_OF_LICENSE)}
                                    />
                                </div>
                            </div>
                            <div className="mb-10">
                                <h2 className="font-title mb-6 text-2xl">What is your Profession?</h2>
                                <RadioGroup options={PROFESSION_OPTIONS} formName="profession" />
                            </div>
                            <div className="mb-4">
                                <div className="mb-6">
                                    <h2 className="mb-6 font-title text-2xl">Privacy</h2>
                                    <div className="mb-2 font-body">
                                        <Field type="checkbox" id="privacyTerms" name="privacyTerms" />
                                        <label className="ml-2 text-base" htmlFor="privacyTerms">
                                            {/* TODO: we need to remove this important style override once we deprecate the old design semantic.min.css */}
                                            I have read and agree to the entire Healthcasts <a className="!underline !text-purple-500" target="_blank" rel="noopener noreferrer" href={privacyPolicyLink}> Privacy Policy Statement.</a>
                                        </label>
                                        {errors.privacyTerms && touched.privacyTerms && <Error message={errors.privacyTerms} />}
                                    </div>
                                    <div className="mb-2 font-body">
                                        <Field type="checkbox" id="emailTerms" name="emailTerms" />
                                        <label className="ml-2 text-base" htmlFor="emailTerms">I agree to receive email communication from Healthcasts.</label>
                                        {errors.emailTerms && touched.emailTerms && <Error message={errors.emailTerms} />}
                                    </div>
                                </div>
                                {values.stateOfLicense === 'CA' && (
                                    <div>
                                        <h3 className="text-base font-body mb-4">California Residents</h3>
                                        <div className="text-base font-body">
                                            <Field type="checkbox" id="caTerms" name="caTerms" />
                                            <label className="ml-2 text-base" htmlFor="caTerms">
                                                {/* TODO: we need to remove this important style override once we deprecate the old design semantic.min.css */}
                                                Opt out of personal information sale or sharing. (For California residents under <a className="!underline !text-purple-500" target="_blank" rel="noopener noreferrer" href="https://oag.ca.gov/privacy/ccpa">CCPA.</a>)
                                            </label>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <Button
                                className="py-4 px-6 text-base justify-center mt-4 min-w-36"
                                type="submit"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? [<ReloadIcon key="loading-spinner" className="mr-2 h-4 w-4 animate-spin" />, 'Submitting...'] : 'Submit'}
                            </Button>
                            {shouldShowErrorMessageSummary(errors, touched) && (
                                <Error message="Some required fields are missing or have issues." containerStyles="mt-4" />
                            )}
                            {/* TODO: border isn't showing up.  */}
                            <div className="flex flex-col items-center justify-center text-base mt-6 pt-6 border-t border-gray-500">
                                <p className="text-center font-body">
                                    {/* TODO: we need to remove this important style override once we deprecate the old design semantic.min.css */}
                                    Issues creating an account? <a className="!underline !text-purple-500" target="_blank" rel="noopener noreferrer" href={links.infoPages.support}>Contact Us</a>
                                </p>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

Registration.propTypes = {
    specialties: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
    })),
    fetchSpecialties: PropTypes.func.isRequired,
    dispatchError: PropTypes.func.isRequired,
    additionalPayload: PropTypes.objectOf(PropTypes.any),
    location: PropTypes.shape().isRequired,
};

Registration.defaultProps = {
    specialties: [],
    additionalPayload: {},
};

const mapStateToProps = state => ({
    specialties: state.DATA.SPECIALTIES.specialties,
});

const mapDispatchToProps = dispatch => ({
    fetchSpecialties: () => dispatch(loadSpecialties()),
    dispatchError: errorPayload => dispatch(showErrorToast(errorPayload)),
});


export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
)(Registration);
