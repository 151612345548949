import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ChatBubbleIcon } from '@radix-ui/react-icons';
import './tile.scss';
import Chip from '../../components/Chip';

const tileText = {
    Clinical: {
        chipText: 'Clinical Consensus',
        ctaText: 'Hear from Experts',
        color: 'light-blue',
    },
    Community: {
        chipText: 'Community Consensus',
        ctaText: 'Hear from your Community',
        color: 'light-blue',
    },
    'Expert On Call': {
        chipText: 'Expert On Call',
        ctaText: 'Hear from Experts',
        color: 'purple',
    },
    'Event Coverage': {
        chipText: 'Event Coverage',
        ctaText: 'Hear from Experts',
        color: 'orange',
    },
};

const Tile = ({
    assetType,
    title,
    ctaText,
    authorName,
    authorTitle,
    image,
    docdxUrl,
}) => {
    const imageClassNames = clsx('tile-image', assetType.toLowerCase().replaceAll(' ', '-'));

    const getImageUrl = imageObj => (
        imageObj ? imageObj.url : null
    );

    return (
        <a className="ed_link" href="#" ed_path={docdxUrl} program_id="####" fk_adid="0" num_views="0" viewd_count="0">
            <div className="tile-content col-xs-12 col-sm-6 col-md-4 col-lg-3 hc-card">
                <a className="tile-link ed_link" href="#" ed_path={docdxUrl} program_id="####" fk_adid="0" num_views="0" viewd_count="0">
                    <div className="card hc-bg-color-9 tile-7098">
                        <Chip variant={tileText[assetType].color}>{tileText[assetType].chipText}</Chip>
                        <img className={imageClassNames} src={getImageUrl(image)} alt="Tile" />
                        <h4 className="title">{title}</h4>
                        <p className="sub-title">{!!authorName && authorName} {!!authorTitle && authorTitle}</p>
                        <div className="cta">
                            <ChatBubbleIcon />
                            <p className="cta-text">{ctaText || tileText[assetType].ctaText}</p>
                        </div>
                    </div>
                </a>
            </div>
        </a>
    );
};

Tile.propTypes = {
    assetType: PropTypes.oneOf(['Clinical', 'Community', 'Expert On Call', 'Event Coverage']).isRequired,
    title: PropTypes.string,
    ctaText: PropTypes.string,
    authorName: PropTypes.string,
    authorTitle: PropTypes.string,
    image: PropTypes.shape({
        url: PropTypes.string,
    }),
    docdxUrl: PropTypes.string.isRequired,
};

Tile.defaultProps = {
    title: null,
    ctaText: null,
    authorName: null,
    authorTitle: null,
    image: {},
};

export default Tile;
