import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { consensusShape } from '../../components/ConsensusSummarySubmission/shared/types';
import SummaryContent from './content';
import api from '../../api';

const SummaryPreview = ({
    fetchConsensusSummary, resourceId, resourceStatus, resource, notFound, userId,
}) => {
    const [errorText, setErrorText] = useState();

    useEffect(() => {
        if (resourceId && resourceStatus) {
            fetchConsensusSummary(resourceId, resourceStatus, setErrorText);
        }
    }, [resourceId, resourceStatus]);

    useEffect(() => {
        async function trackUserContentView() {
            await api.post('/impression/case_consensus', {
                data: {
                    contentId: resourceId,
                    userId,
                },
            });
        }
        if (resourceId && userId) {
            trackUserContentView();
        }
    }, [resourceId, userId]);

    if (!resource && !errorText) {
        return null;
    }

    if (errorText) {
        return (
            <div>
                {errorText}
            </div>
        );
    }

    // publishedAt is null when the summary is not published
    if (!resource.publishedAt) {
        // TODO: determine the ideal logic for a user trying
        // to access a summary that is not yet published or was
        // unpublished by an admin.
        notFound();
    }

    return <SummaryContent resourceId={resourceId} resourceStatus={resourceStatus} resource={resource} />;
};

SummaryPreview.propTypes = {
    resourceId: PropTypes.string,
    resourceStatus: PropTypes.string,
    resource: PropTypes.shape({ data: consensusShape }),
    fetchConsensusSummary: PropTypes.func.isRequired,
    notFound: PropTypes.func.isRequired,
    userId: PropTypes.number.isRequired,
};

SummaryPreview.defaultProps = {
    resourceId: null,
    resourceStatus: 'published',
    resource: null,
};


export default SummaryPreview;
